/* eslint-env browser */
import React, { Component } from 'react';
import styled from 'styled-components';
import { LazyImage } from 'react-lazy-images';
import Layout from './layout';
import SEO from './seo';
import _map from 'lodash.map';
import _get from 'lodash.get';
import _sortBy from 'lodash.sortby';
import _reverse from 'lodash.reverse';
import _keys from 'lodash.keys';
import _forEach from 'lodash.foreach';
import _reduce from 'lodash.reduce';
import moment from 'moment-mini';
import { Element, scroller } from 'react-scroll';
import ReactPlayer from 'react-player';
import _uniqueId from 'lodash.uniqueid';

import { mediumBreakpointMinus, imageBase } from '../constants';

const MainContentBox = styled.div`
  height: 100%;
  width: 100vw;
  margin: 30px auto 0 auto;
  padding: 0 30px;
  background: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    flex-direction: column;
    padding: 0;
  }
`;

const BlogContentBox = styled.main`
  box-sizing: border-box;
  width: calc(75% - 17px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 0 40px;
  overflow-y: auto;
  // desktop
  position: fixed;
  left: calc(25% + 15px);
  height: calc(100vh - 209px);
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    width: 100% !important;
    left: unset;
    position: unset;
    padding: 0 10px;
    overflow-x: hidden;
    height: 100%;
  }
  a {
    text-decoration: underline;
  }
`;

const DateListBox = styled.div`
  width: 25%;
  height: calc(100vh - 209px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(200, 200, 200, 0.15);
  justify-content: flex-start;
  padding: 0 10px 10px 10px;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    display: none;
  }
`;

const BlogPost = styled.article`
  font-size: 18px;
  width: 100%;
  p {
    line-height: 24px;
    text-align: justify;
  }
  img {
    max-width: 100%;
    margin: 18px auto;
    display: block;
  }
  iframe {
    width: 100%;
  }
  .blog-post-element {
    padding-right: 36%;
  }
  h6 {
    margin: 8px 0 18px 0;
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    padding: 0;
    width: 92%;
    margin: 0 auto;
    .blog-post-element {
      padding-right: 0;
    }
  }
`;

export const PostBody = styled.div`
  p {
    line-height: 24px;
    text-align: justify;
    font-size: 18px;
    margin: 18px 0;
  }
`;

const DateInPost = styled.p`
  font-style: italic;
  margin-top: 5px;
`;

const PostTitle = styled.h2`
  margin: 10px 0 0 0;
  font-weight: 600;
  font-size: 18px;
`;

const PostHr = styled.hr`
  width: 85%;
  opacity: 0.5;
`;

const Spacer = styled.div`
  width: 100%;
  height: 100px;
`;

const MonthBox = styled.div`
  font-size: 18px;
  width: 100%;
  p {
    line-height: 24px;
  }
`;

const DateInList = styled.p`
  margin-top: 5px;
  font-size: 14px;
  text-transform: uppercase;
`;

const DateListTitle = styled.h5`
  font-weight: 400;
  margin-top: 5px;
  a {
    &:hover {
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

export const PostImageBlock = styled.figure`
  width: 100%;
  margin: 0;
  img {
    width: 100%;
    margin: 18px auto;
    display: block;
  }
`;

export const PostImageCaption = styled.figcaption`
  width: 100%;
  text-align: center;
  margin: 8px 0px 18px;
  font-size: 14px;
  font-weight: 600;
`;

export const VideoDisplayWrapper = styled.div`
  margin: 18px 0;
  width: 100%;
  background-color: green;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

class BlogPage extends Component {
  disableRightClick() {
    // e.preventDefault();
  }

  scrollToBlogPost(id) {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'blog-content-box',
    });
  }

  render() {
    let blogPosts = _get(this.props.data, 'jmonehillart.listBlogPosts.items');

    let dates = {};
    let monthBlocksForLinks = [];
    const today = moment();

    // process and enhance each of the posts
    blogPosts = _reduce(
      blogPosts,
      (acc, blogPost) => {
        if (!blogPost.visible) return acc;

        blogPost.domId = _get(blogPost, ['title']).replace(/\W+/g, '-');
        // sort out date related things per post
        const date = moment(_get(blogPost, ['date']));
        const isTodayOrInPast = date.isSameOrBefore(today);

        if (!isTodayOrInPast) return acc;

        //add posts to the date organizing object
        if (!dates[date.year()]) {
          dates[date.year()] = {};
        }
        if (!dates[date.year()][date.month()]) {
          dates[date.year()][date.month()] = [];
        }
        dates[date.year()][date.month()].push(blogPost);
        blogPost.momentDate = date;
        blogPost.timeStamp = blogPost.momentDate.unix();

        acc.push(blogPost);
        return acc;
      },
      []
    );

    blogPosts = _reverse(_sortBy(blogPosts, 'timeStamp'));

    const blogPostContentBlocks = _map(blogPosts, (blogPost, index) => {
      const sections = JSON.parse(_get(blogPost, 'meta'));
      const date = moment(_get(blogPost, ['momentDate']));

      const isLastPost = blogPosts.length === index + 1;

      return (
        <BlogPost key={_get(blogPost, 'id')}>
          <Element
            name={_get(blogPost, ['domId'])}
            className="blog-post-element"
          >
            <PostTitle>{_get(blogPost, 'title')}</PostTitle>
            <DateInPost>{date.format('LL')}</DateInPost>
            {_map(sections, (section, idx) => {
              if (section.type === 'body') {
                return (
                  <PostBody
                    key={`blog-post-body-${idx}`}
                    dangerouslySetInnerHTML={{ __html: section.content }}
                  />
                );
              }

              if (section.type === 'photo') {
                let imageSrc = section.imageSrc;
                const link = _get(section, 'link', '');

                if (!imageSrc.includes('http')) {
                  imageSrc = `${imageBase}${imageSrc}`;
                }

                const renderImageChunk = () => {
                  return (
                    <LazyImage
                      observerProps={{ threshold: 0.05 }}
                      src={imageSrc}
                      alt={section.caption}
                      placeholder={({ ref }) => (
                        <img
                          ref={ref}
                          src={imageSrc.replace('.jpg', '-low-res.jpg')}
                          alt={section.caption}
                        />
                      )}
                      actual={({ imageProps }) => <img {...imageProps} />}
                    />
                  );
                };

                if (link !== '') {
                  return (
                    <PostImageBlock key={`photo-display-${idx}`}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {renderImageChunk()}
                      </a>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        <PostImageCaption>{section.caption}</PostImageCaption>
                      </a>
                    </PostImageBlock>
                  );
                }

                return (
                  <PostImageBlock key={`photo-display-${idx}`}>
                    {renderImageChunk()}
                    <PostImageCaption>{section.caption}</PostImageCaption>
                  </PostImageBlock>
                );
              }

              if (section.type === 'video') {
                const vimeoId = _get(section, 'vimeoId', '');
                const caption = _get(section, 'caption', '');
                const link = _get(section, 'link', '');

                const CaptionBlock =
                  link !== '' ? (
                    <a
                      href={link}
                      key={`video-caption-${index}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <PostImageCaption>{caption}</PostImageCaption>
                    </a>
                  ) : (
                    <PostImageCaption key={`video-caption-${index}`}>
                      {caption}
                    </PostImageCaption>
                  );

                let VideoElements = [
                  <VideoDisplayWrapper key={`video-display-${idx}`}>
                    <ReactPlayer
                      url={`https://vimeo.com/${vimeoId}`}
                      width="100%"
                      height="100%"
                      playing={false}
                      controls={true}
                      style={{
                        backgroundColor: 'white',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                      }}
                    />
                  </VideoDisplayWrapper>,
                ];

                VideoElements.push(CaptionBlock);

                return VideoElements;
              }
            })}
            <PostHr />
            {isLastPost && <Spacer />}
          </Element>
        </BlogPost>
      );
    });

    // create date listings
    _forEach(_reverse(_keys(dates).sort()), yearNumber => {
      _forEach(_reverse(_keys(dates[yearNumber]).sort()), monthNumber => {
        const postsInAMonth = _map(
          _reverse(dates[yearNumber][monthNumber]),
          (blogPost, idx) => {
            return (
              <DateListTitle key={`date-list-title-${idx}`}>
                &bull;&nbsp;
                <a
                  href="#"
                  onClick={() => {
                    this.scrollToBlogPost(_get(blogPost, 'domId'));
                  }}
                >
                  {_get(blogPost, 'title')}
                </a>
              </DateListTitle>
            );
          }
        ); // end looping through posts

        const dateString = _get(dates, [
          yearNumber,
          monthNumber,
          0,
          'momentDate',
        ]).format('MMMM YYYY');

        monthBlocksForLinks.push(
          <MonthBox key={_uniqueId()}>
            <DateInList>{dateString}</DateInList>
            {postsInAMonth}
            <PostHr />
          </MonthBox>
        );
      }); //end a month
    }); // end outer map through years

    //main return
    return (
      <Layout fixed>
        <SEO
          title="Jennifer Mone Hill Artist - Blog"
          keywords={[
            'artist blog',
            'buy Jennifer Moné Hill art',
            'Jen Hill Artist',
            'Jennifer Mone Hill',
            'Jen Hill',
            'Jen Hill Artist',
            '2D art',
            'paintings',
            'commission art',
            'one of a kind',
            'original art',
            'buy original art',
          ]}
          description="Jennifer Mone Hill Artist Blog"
        />
        <MainContentBox onContextMenu={e => this.disableRightClick(e)}>
          <DateListBox>{monthBlocksForLinks}</DateListBox>
          <BlogContentBox id="blog-content-box">
            <BlogPost>
              <div className="blog-post-element">
                <PostTitle>Hello</PostTitle>
                <PostBody>
                  <p>
                    Hello! Thanks for checking out my site. I thought a good way
                    for you to get to know me better might be to write a blog. I
                    am, we all are, more than one thing. What is revealed in my
                    artist statement is just a tiny bit of me. I think the
                    number one misconception about me is that I am a super
                    serious person. Yes, I have my serious moments, but I think
                    people confuse my earnestness, deep interest, and lack of
                    upspeak (and possibly BRF) for solemnity. Inside I am a
                    jokester. My years as a commercial photographer may have
                    made me more disciplined, but I am gradually shaking off
                    that suit of armor. With this blog, I am attempting to let
                    the public into the private me. It may take reading a few
                    entries to get the hang of me, and I hope you’ll be
                    interested enough to keep reading.
                    <br />- Jen
                  </p>
                </PostBody>
                <PostHr />
              </div>
            </BlogPost>
            {blogPostContentBlocks}
          </BlogContentBox>
        </MainContentBox>
      </Layout>
    );
  }
}


export default BlogPage;
