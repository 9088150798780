/* eslint-env browser */
import React from 'react';
import BlogPage from '../components/blogPageInner';
import { graphql, StaticQuery } from 'gatsby';

const BlogPageWrapper = () => (
  <StaticQuery
    query={graphql`
      query allBlogPosts {
        jmonehillart {
          listBlogPosts {
            items {
              date
              html
              id
              meta
              title
              visible
            }
          }
        }
      }
    `}
    render={data => {
      return <BlogPage data={data} />;
    }}
  />
);

export default BlogPageWrapper;
